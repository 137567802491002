import React from "react";
import InstagramIcon from "../../images/svg/InstagramIcon";
import MailIcon from "../../images/svg/MailIcon";
import { GetSettings } from "../../api";
import { useQuery } from "@tanstack/react-query";


const Footer = () => {
  // const instagramUrl = "https://www.instagram.com/shimohnee/?igsh=ZmQ4c2E3MHE1aWVt&utm_source=qr";

  const { data:settings , isLoading } = useQuery(
    {queryKey : ['Settings'],
   queryFn:  () => GetSettings( ), 
  staleTime: 1000 * 60 * 5
  }
  );

 const InstagramId = !isLoading && settings?.data?.socialLinks?.filter((data)=> data?.serial_number === 2)
 const EmailId = !isLoading && settings?.data?.socialLinks?.filter((data)=> data?.serial_number === 1)
 const InstagramUrl =  InstagramId?.[0]?.url || ''
 const EmailUrl =  EmailId?.[0]?.url || ''
//  const mailtoLink = `mailto:shimonipatel2003@gmail.com`;
 const mailtoLink = `mailto:${EmailUrl}`;



  return (
    <footer className="container   pt-[3.3vmax] pb-[3.3vmax] ">
     <div className="flex justify-center gap-3 ">
     <a href={mailtoLink}>
     <MailIcon/>
     </a>
     <a href={InstagramUrl} target="_blank" rel="noopener noreferrer">
      <InstagramIcon/>
    </a>
      </div>  
     
    </footer>
  );
};
export default Footer;
