import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import logo from "../images/shimohneeLogo.png";
import { Link, useLocation } from "react-router-dom";
import { Rotate as Hamburger } from "hamburger-react";
import { useQuery } from "@tanstack/react-query";
import { GetSettings, fetchCategories } from "../api";
import { Helmet } from "react-helmet";

const AppNavbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const ImageBaseUrl = `${baseUrl}/assets/front/img`;

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  const location = useLocation();
  const currentPath = location.pathname;

  const [activeLink, setActiveLink] = useState(currentPath);

  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };
  const { data } = useQuery({
    queryKey: ["Categories"],
    queryFn: () => fetchCategories(),
    staleTime: 1000 * 60 * 5,
  });
  const { data: settings } = useQuery({
    queryKey: ["Settings"],
    queryFn: () => GetSettings(),
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Set the scroll behavior to smooth
      });
    };

    scrollToTop();
  }, [currentPath]);

  const dynamicFavicon = `${ImageBaseUrl}/${settings?.data?.favicon}`;

  return (
    <>
      {dynamicFavicon && (
        <Helmet>
          <link rel="icon" type="image/png" href={dynamicFavicon} />
        </Helmet>
      )}
      <Navbar collapseOnSelect expand="lg" className="container nav-bar">
        <div style={{ width: "220px", height: "47px" }}>
          {settings?.data?.logo && (
            <Navbar.Brand>
              <Link to="/">
                <img
                  src={`${ImageBaseUrl}/${settings?.data?.logo}`}
                  width={220}
                  alt="logo"
                />
              </Link>
            </Navbar.Brand>
          )}
        </div>

        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <Hamburger toggled={isOpen} toggle={handleToggle} />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-navs">
          <Nav className="ml-auto">
            <Nav.Link className={`navHeader roc-grotesk`}>
              <Link
                to="/"
                className={`text-black text-decoration-none  ${
                  activeLink === "/" ? "border-bt" : ""
                } `}
                onClick={() => handleNavLinkClick("/")}
              >
                Home
              </Link>
            </Nav.Link>

            <div
              className={`custom-dropdown ${isDropdownOpen ? "open" : ""}`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Nav.Link
                className=" navHeader "
                // onClick={() => handleNavLinkClick("work")}
              >
                <span
                  className={`text-black text-decoration-none navHeader  ${
                    currentPath.startsWith("/category/") ? "border-bt" : ""
                  } `}
                >
                  Work
                </span>
              </Nav.Link>
              {isDropdownOpen && (
                <div
                  className="dropdown-menu navHeader  fs18px "
                  onClick={() => handleNavLinkClick("fashion")}
                >
                  {/* <Link to="/fashionArt">Fashion Design</Link>
                <Link to="/fineArt">Fine Art</Link> */}
                  {data?.data?.map((value) => (
                    <Link
                      to={`/category/${value?.slug}`}
                      onClick={() => handleNavLinkClick("work")}
                    >
                      {value?.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <Nav.Link className="navHeader">
              <Link
                to="/about"
                // className='text-black text-decoration-none'
                className={`text-black text-decoration-none  ${
                  activeLink === "/about" ? "border-bt" : ""
                } `}
                onClick={() => handleNavLinkClick("/about")}
              >
                About
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default AppNavbar;
