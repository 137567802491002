import React from "react";

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        d="M17.557 3.211H2.443A2.446 2.446 0 000 5.654v8.692a2.446 2.446 0 002.443 2.443h15.114A2.446 2.446 0 0020 14.346V5.654a2.446 2.446 0 00-2.443-2.443zm-1.194 1.594L10 8.905l-6.363-4.1h12.726zm1.194 10.39H2.443a.85.85 0 01-.85-.849V5.7l7.915 5.089c.011.007.022.011.033.017a.922.922 0 00.224.092l.02.005c.071.016.143.027.214.027h.002a.92.92 0 00.214-.027l.02-.005a.93.93 0 00.224-.092c.011-.006.023-.01.033-.017l7.915-5.09v8.647c0 .468-.382.85-.85.85z"
      ></path>
    </svg>
  );
}

export default MailIcon;