// import Image from 'next/image';
import styles from "./loader.module.css";
// import logo from "../images/shimohneeLogo.png";
// import logo from "../../images/shimohneeLogo.webp";
import { useQuery } from "@tanstack/react-query";
import { GetSettings } from "../../api";

const Loader = () => {
  const { data: settings } = useQuery({
    queryKey: ["Settings"],
    queryFn: () => GetSettings(),
    staleTime: 1000 * 60 * 5,
  });
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const ImageBaseUrl = `${baseUrl}/assets/front/img`;

  return (
    <>
      <span
        className="d-flex  flex-column align-items-center justify-content-center "
        style={{ height: "100vh" }}
      >
        <span className={styles.loading} />
        {settings?.data?.logo && (
          <img
            // src={logo}
            src={`${ImageBaseUrl}/${settings?.data?.logo}`}
            height={85}
            width={85}
            style={{ marginTop: "20px" }}
            alt="loader"
          />
        )}
      </span>
    </>
  );
};

export default Loader;
