import axios from "axios";
import { API_ENDPOINTS } from "./apiendpoints";
const baseUrl = process.env.REACT_APP_BASE_URL
// console.log(baseUrl, 'base')
export const fetchBanner = async ( ) => {
    const response = await axios.get(
      `${baseUrl}${API_ENDPOINTS?.Banner}`
    );
    return response.data;
  };
export const fetchCategories = async ( ) => {
    const response = await axios.get(
      `${baseUrl}${API_ENDPOINTS?.Categories}`
    );
    return response.data;
  };
export const fetchCategoriesByProduct = async (slug) => {
    const response = await axios.get(
      `${baseUrl}${API_ENDPOINTS?.ProductByCategory}/${slug}`
    );
    return response.data;
  };
export const fetchProductBySlug = async (slug) => {
    const response = await axios.get(
      `${baseUrl}${API_ENDPOINTS?.ProductBySlug}/${slug}`
    );
    return response.data;
  };
export const pageBySlug = async (slug) => {
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS?.PageBySlug}/${slug}`
  );
  return response.data;
};
export const GetSettings = async () => {
  const response = await axios.get(
    `${baseUrl}${API_ENDPOINTS?.Settings}`
  );
  return response.data;
};