import PropTypes from "prop-types";
import React, { Fragment } from "react";
import AppNavbar from "../AppNavbar";
import Footer from "../Footer/Footer";

const LayoutOne = ({ children }) => {
  const styles = {
    body: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      margin: 0,
    },
    main: {
      flex: 1,
    },
    footer: {
      bottom: 0,
    },
  };
  return (
    <>
      <div style={styles.body}>
        <div style={styles.main}>
          <AppNavbar />
          {children}
        </div>
        <footer style={styles.footer}>
          <Footer />
        </footer>
      </div>
    </>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.string,
};

export default LayoutOne;
