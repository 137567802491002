import "./App.css";
import { Routes, Route } from "react-router-dom";
import React, { useEffect, lazy, Suspense } from "react";
import Loader from "./Component/loader/loader.jsx";
// import AppNavbar from "./Component/AppNavbar.jsx";
// import Footer from "./Component/Footer/Footer.jsx";
import LayoutOne from "./Component/layouts/LayoutOne.jsx";
import { Helmet } from 'react-helmet';

const DetailsPage = lazy(() =>
  import("./Component/WorkDetailsPage/DetailsPage.jsx")
);
const Home = lazy(() => import("./Component/Home"));
const AboutUs = lazy(() => import("./Component/About/AboutUs.jsx"));
const Categories = lazy(() => import("./Component/Categories/Categories.jsx"));
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 const dynamicFavicon = ''

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={dynamicFavicon} />
      </Helmet>
    {/* <AppNavbar/> */}
      <Routes>
        <Route
          path={"/"}
          element={
            <Suspense fallback={<Loader />}>
              <LayoutOne>

              <Home />
              </LayoutOne>
            </Suspense>
          }
        />
        <Route
          path={"/about"}
          element={
            <Suspense fallback={<Loader />}>
              <AboutUs />
            </Suspense>
          }
        />
        <Route
          path={"/category/:name"}
          element={
            <Suspense fallback={<Loader />}>
              <Categories />
            </Suspense>
          }
        />
        <Route
          path={"/:name"}
          element={
            <Suspense fallback={<Loader />}>
              <DetailsPage />
            </Suspense>
          }
        />
      </Routes>
      {/* <Footer/> */}
    </>
  );
}

export default App;
